import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Application } from "../Components/Modules/ApplicationModules/Application";
import { DesignedButton } from "../Components/UI/DesignedButton";
import { ApplicationApi } from "../Api/ApplicationApi";
import headPuzzleRight from "../Assets/Images/white_empty_puzzle_right.png";
import headPuzzleLeft from "../Assets/Images/white_empty_puzzle_left.png";
import { useNavigate } from "react-router-dom";
import VideoPopUp from "../Components/Modules/PopUp/VideoPopUp";

export const ApplicationPage = () => {
  const navigate = useNavigate();
  const [participantList, setParticipantList] = useState([]);
  const [teamInfo, setTeamInfo] = useState({ name: "", url: "", video: "" });
  const [count, setCount] = useState(0);
  const [solo, setSolo] = useState(true);
  const [showVideoPopUp, setShowVideoPopUp] = useState(true);
  const [approve, setApprove] = useState(false);
  const [fillStatus, setFillStatus] = useState(false);
  const [load, setLoad] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function listCallback(list) {
    setParticipantList(list);
  }

  function teamCallback(team) {
    setTeamInfo(team);
  }

  function soloCallback(status) {
    setSolo(status);
    setApprove(false);
  }

  function docCallback(status) {
    setApprove(status);
  }

  function openPop(status) {
    setShowVideoPopUp(status);
  }

  useEffect(() => {
    let count = 0;

    if ((!solo && !teamInfo.name) || (!solo && teamInfo.name.trim() === "")) {
      count++;
    }

    if (!teamInfo.url || teamInfo.url.trim() === "") {
      count++;
    }
    if (!teamInfo.video || teamInfo.video.trim() === "") {
      count++;
    }

    participantList.map((item) => {
      if (!item.firstName || item.firstName.trim() === "") {
        count++;
      }
      if (!item.secondName || item.secondName.trim() === "") {
        count++;
      }

      if (!item.thirdName || item.thirdName.trim() === "") {
        count++;
      }
      if (!item.date || item.date.trim() === "") {
        count++;
      }

      if (!item.email) {
        count++;
      }

      if (!item.gender || item.gender.trim() === "") {
        count++;
      }

      if (!item.homeAddress || item.homeAddress.trim() === "") {
        count++;
      }

      if (!item.phoneNumber || item.phoneNumber.trim() === "") {
        count++;
      }

      if (!item.studyPlace && !item.workPlace) {
        count++;
      }

      if (
        (item.studyPlace || item.workPlace) &&
        !item.faculty &&
        !item.jobTitle
      ) {
        count++;
      }

      if (solo && (approve === false || !approve)) {
        count++;
      }

      if (solo && !item.medCertificate) {
        count++;
      }
    });

    setCount(count);
  }, [
    approve,
    participantList,
    solo,
    teamInfo.name,
    teamInfo.url,
    teamInfo.video,
  ]);

  async function fetchTeam() {
    if (participantList.find((item) => item.disability === true)) {
      setTeamInfo({ ...teamInfo, disability: true });
    }

    await ApplicationApi.sendTeam(teamInfo);
  }

  async function fetchMember(member) {
    await ApplicationApi.sendTeamMember(member);
  }

  async function createTeam() {
    if (!solo && participantList.length < 2) {
      alert("Минимальное количество участников - 2");
      return;
    }

    const findEmail = participantList.find(
      (item) => !validateEmail(item.email)
    );

    if (findEmail) {
      alert("Почта члена команды введена неверно");
    }

    setLoad(true);
    if (count === 0 && !findEmail) {
      await fetchTeam();

      participantList.map(async (member) => {
        await fetchMember(member);
      });

      alert("Ваша заявка принята!");
      navigate("/team");
    } else {
      alert("Заполните все поля");
      setFillStatus(true);
      setLoad(false);
    }
    setLoad(false);
  }

  useEffect(() => console.log(participantList));

  return (
    <div className="applicationPage">
      <div className="applicationHead">
        <img alt="" src={headPuzzleLeft} />
        Подача заявки
        <img alt="" src={headPuzzleRight} />
      </div>
      <Application
        listCallback={listCallback}
        teamCallback={teamCallback}
        openCallback={(e) => openPop(e)}
        soloCallback={soloCallback}
        docCallback={docCallback}
        fillStatus={fillStatus}
      />
      {load ? (
        <ReactLoading
          type="spinningBubbles"
          color="black"
          height={"50px"}
          width={"50px"}
        />
      ) : (
        <DesignedButton text={"Далее"} callback={createTeam} />
      )}

      {showVideoPopUp && <VideoPopUp close={(e) => openPop(e)} />}
    </div>
  );
};
